import React from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

export const Main = () => {
  return (
    <Container>
      <Wrapper>
        <Logo src="https://imgur.com/ikDQSDX.png"></Logo>
        <NavLinks>
          <Left>
            <TextLink
              target="blank"
              href="https://www.youtube.com/channel/UCsIuO7hcGGvbj7G3c9JRqvw"
            >
              WATCH
            </TextLink>
            <TextLink
              target="blank"
              href="https://falseindigo-3505.myshopify.com/"
            >
              STORE
            </TextLink>
          </Left>
          <Right>
            <IconLink
              target="blank"
              href="https://www.facebook.com/falseindigomusic/"
              className="fab fa-facebook"
            ></IconLink>
            <IconLink
              target="blank"
              href="https://www.instagram.com/falseindigomusic/?hl=en"
              className="fab fa-instagram"
            ></IconLink>
            <IconLink
              target="blank"
              href="https://www.tiktok.com/@falseindigomusic"
              className="fab fa-tiktok"
            ></IconLink>
            <IconLink
              target="blank"
              href="https://www.youtube.com/channel/UCsIuO7hcGGvbj7G3c9JRqvw"
              className="fab fa-youtube"
            ></IconLink>
            <IconLink
              target="blank"
              href="https://music.apple.com/us/artist/false-indigo/1436981596"
              className="fab fa-apple"
            ></IconLink>
            <IconLink
              target="blank"
              href="https://open.spotify.com/artist/1pkXdgsN3SddrnalmaHOXM"
              className="fab fa-spotify"
            ></IconLink>
          </Right>
        </NavLinks>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  background-image: url("https://imgur.com/PUZyDi4.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  height: 100vh;
  display: flex;
  justify-content: center;
`;
const Logo = styled.img`
  width: 100%;
`;
const Left = styled.div`
  margin-right: ${isMobile ? "0" : "auto"};
  display: flex;
  width: ${isMobile ? "100%" : "45%"};
  justify-content: ${isMobile ? "space-evenly" : "undefined"};
`;
const Right = styled.div`
  margin-left: ${isMobile ? "0" : "auto"};
  display: flex;
  width: ${isMobile ? "100%" : "30%"};
  justify-content: space-between;
`;

const Wrapper = styled.div`
  width: 70%;
  position: absolute;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const NavLinks = styled.div`
  display: flex;
  justify-content: center;
  color: #fff;
  padding: 0 0.5rem;
  flex-direction: ${isMobile ? "column" : "row"};
`;
const TextLink = styled.a`
  text-decoration: none;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  color: #fff;
  &:hover {
    color: #cb9c38;
  }
  padding: ${isMobile ? "1rem" : "0 4rem 0 0"};
`;
const IconLink = styled.a`
  text-decoration: none;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  color: #fff;
  &:hover {
    color: #cb9c38;
  }
`;
